<template>
  <div class="h-screen font-flash lg:text-lg 2xl:text-xl bg-sand scroll-smooth">
    <div
      class="w-full fixed top-0 bg-dark text-flash flex h-32 z-10 items-center leading-none border-flash border-b-4 xl:border-b-0"
    >
      <img alt="Vue logo" src="./assets/logo.png" class="h-32" />

      <h1 class="text-6xl font-extrabold text-left -ml-8">PAY</h1>

      <p class="flex-grow justify-self-end text-right text-xs sm:text-lg mr-4">
        FPAY, 2023 © All Rights Reserved
      </p>
    </div>

    <div
      class="w-full md:w-2/3 lg:w-3/5 xl:w-1/2 mx-auto flex items-center h-full pt-32 pb-24 sm:pb-16"
    >
      <div
        class="bg-dark lg:m-8 border-flash xl:border-t-4 xl:border-l-4 text-light p-4 snap-start snap-always h-full sm:h-auto overflow-y-scroll no-scrollbar"
      >
        <h2 class="text-4xl font-bold h-14 text-flash border-b-4 border-flash">
          Про Нас
        </h2>

        <div class="text-xl sm:text-2xl p-8 text-justify">
          <p class="mb-4 lg:mb-8">
            Товариство з обмеженою відповідальністю “ФПЕЙ" здійснює діяльність
            щодо підтримання роботи Веб-сайту, який забезпечує роботу
            автоматизованої системи доступу з можливістю активації зарядних
            станцій для заряджання електроавтомобілів та інтегрованого доступу
            до системи еквайрингу Банку, що надає можливість оплати за послуги
            заряджання.
          </p>
          <p class="mb-4 lg:mb-8">
            Сайт містить відомості про локації розміщення зарядних станцій для
            заряджання акумуляторів електроавтомобілів та надає можливість
            вибору типу порту зарядки на конкретних локаціях, можливість
            активізації процесу зарядки електроавтомобілів та оплати за нього за
            допомогою інтегрованого модуля еквайрингу Банку.
          </p>
          <p class="mb-4 lg:mb-0">
            Послуги "ФПЕЙ" з користування Сайтом є безкоштовними для
            користувача, він сплачує виключно за послуги заряджання
            електроавтомобіля.
          </p>
        </div>
      </div>
    </div>

    <div
      class="w-full bg-dark text-flash fixed bottom-12 h-12 sm:hidden border-flash border-b-2 flex items-center"
    >
      <p class="mx-auto">📍 Дніпро, вул. Панікахи, будинок 2Б</p>
    </div>

    <div
      class="w-full bg-dark text-flash fixed bottom-0 h-12 sm:h-16 flex items-center divide-flash divide-x-2 justify-stretch"
    >
      <div class="mx-auto w-1/2 sm:w-1/3 px-2 min-h-16">🏛️ ТОВ "ФПЕЙ"</div>
      <div class="mx-auto sm:w-1/3 px-2 min-h-16 hidden sm:block">
        📍 Дніпро, вул. Панікахи, будинок 2Б
      </div>
      <div class="mx-auto w-1/2 sm:w-1/3 px-2 min-h-16">
        📧 ua.fpay@gmail.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2e302f;
}
</style>
